import { DownOutlined, UserOutlined } from "@ant-design/icons";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
  useOne,
} from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";
import React, { useContext } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import { API_URL } from "config/axios";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
  id: number;
  name: string;
  profile: {
    id: number;
    asset: string;
  };
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
}) => {
  const guide_id = localStorage.getItem("guide_id");
  const { token } = useToken();
  const { mode, setMode } = useContext(ColorModeContext);
  const { data } = useOne<IUser>({
    resource: "local_guides",
    id: guide_id ? guide_id : 0,
  });

  const user = data?.data;

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (isSticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <Switch
          checkedChildren="Dark Mode 🌛"
          unCheckedChildren="Light Mode 🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        />
        <Space style={{ marginLeft: "8px" }} size="middle">
          {user?.name && <Text strong>{user.name.toUpperCase()}</Text>}
          {user?.profile ? (
            <Avatar src={API_URL + user?.profile?.asset} alt={user?.name} />
          ) : (
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: "#1677ff" }}
            />
          )}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};

import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Switch } from "antd";
import { date } from "utils";
export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();

  return (
    <List headerButtons={<></>}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="created_at"
          title="วันที่สร้าง"
          render={(text) => date.thaiFormat(text)}
        />
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="ชื่อแพ็คเกจ" />
        <Table.Column dataIndex="period" title="รูปแบบแพ็คเกจ" />
        <Table.Column
          dataIndex="price"
          title="ราคาแพ็จเกจ (บาท)"
          render={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
        <Table.Column
          dataIndex={["destination_visit", "name"]}
          title="สถานที่ท่องเที่ยว"
        />
        {/* <Table.Column
          dataIndex="local_guide_id"
          title="เผยแพร่"
          render={() => <Switch defaultChecked />}
        /> */}
        <Table.Column
          title=""
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

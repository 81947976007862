import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { PaperClipOutlined } from "@ant-design/icons";
import { API_URL } from "config/axios";

type Props = {
  value?: {
    id: number;
    asset: any;
  };
  onChange?: () => void;
};

const FileUpload: React.FC = (props: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const config = {
    action: `${API_URL}/attachments`,
    onChange: props.onChange,
    name: "asset",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("refine-auth")}`,
    },
  };

  const name = props.value?.asset?.match(/\/([^/]+)$/)[1];
  const url = API_URL + props.value?.asset;

  return (
    <>
      <Upload {...config} showUploadList={false}>
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      {name && (
        <div
          style={{
            padding: "5px 0",
            display: "flex",
            alignItems: "baseline",
            wordBreak: "break-all",
            gap: 3,
          }}
        >
          <PaperClipOutlined />
          <a href={url} target="_blank" style={{ fontSize: 14 }}>
            {name && name}
          </a>
        </div>
      )}
    </>
  );
};

export default FileUpload;

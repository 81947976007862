import { AuthBindings } from "@refinedev/core";
import axiosInstance from "config/axios";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    const response = await axiosInstance.post("/login", {
      username,
      password,
    });

    if (!response.data.error) {
      sessionStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem("user_id", response.data.id);
      if (!response.data.permission) {
        localStorage.setItem("guide_id", "null");
        window.location.replace(`/onboarding/register`);
        console.log("not permission");
      } else {
        console.log("permissible");
        localStorage.setItem("guide_id", response.data.permission.app_able_id);
        window.location.replace("/");
      }

      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  register: async ({ username, password }) => {
    const response = await axiosInstance.post("/sign_up", {
      user: {
        username,
        password,
      },
    });
    if (!response.data.error) {
      sessionStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem("user_id", response.data.id);
      localStorage.setItem("guide_id", "null");
      window.location.replace("/onboarding/register");
      return {
        success: true,
        redirectTo: "/onboarding/register",
      };
    }

    return {
      success: false,
      error: {
        message: "Invalid credentials",
        name: "Invalid credentials",
      },
    };
  },
  logout: async () => {
    localStorage.clear();
    sessionStorage.clear();
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    // const token = localStorage.getItem(TOKEN_KEY);
    // if (token) {
    //   return {
    //     id: 1,
    //     name: "John Doe",
    //     avatar: "https://i.pravatar.cc/300",
    //   };
    // }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};

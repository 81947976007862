import React from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Row, Col, Select } from "antd";
import dayjs from "dayjs";
import UploadOne from "components/UploadOne";
import UploadFile from "components/UploadFile";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

interface ISelect {
  id: number;
  name: string;
}

export const EditProfile: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm({
    queryOptions: {
      enabled: true,
    },
    action: "edit",
  });

  const data = queryResult?.data?.data;

  const handleEdit = async (values: any) => {
    let payload = {
      local_guide: {
        ...values,
      },
    };
    await onFinish(payload);
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="ข้อมูลส่วนตัว">
      <Form {...formProps} layout="vertical" onFinish={handleEdit}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="id" label="ID" hidden>
              <Input size="large" readOnly disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <UploadOne label="โปรไฟล์" name="profile" value={data?.profile} />
          </Col>
          <Col span={12}>
            <Form.Item name="name" label="ชื่อ" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["community", "name"]} label="ชุมชนที่ลงทะเบียน">
              <Input size="large" readOnly disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="address"
              label="ที่อยู่"
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="experience"
              label="ประสบการณ์"
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={24}>
            {/* <Form.Item
              name="detail"
              label="รายละเอียด"
              rules={[{ required: false }]}
            >
              <TextArea rows={4} />
            </Form.Item> */}
            <Form.Item
              label="รายละเอียด"
              name={["detail"]}
              rules={[{ required: false, message: "Please enter the body" }]}
            >
              {/* @ts-ignore */}
              <TextEditor />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="facebook"
              label="เฟสบุ๊ค"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="เบอร์โทร"
              rules={[{ required: false }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="id_card_pdf"
              label="สำเนาบัตรประชาชน"
              rules={[{ required: false }]}
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="house_registration_pdf"
              label="สำเนาทะเบียนบ้าน "
              rules={[{ required: false }]}
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="certificate_pdf"
              label="ประกาศนียบัตร(ถ้ามี)"
              getValueFromEvent={(e: any) => e.file.response}
            >
              <UploadFile />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  ImageField,
} from "@refinedev/antd";
import {
  Descriptions,
  Typography,
  Button,
  Popconfirm,
  Space,
  Tag,
  Image,
} from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const ShowPackage: React.FC<IResourceComponentsProps> = () => {
  const { queryResult, showId } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data?.find(({ id }: any) => id == showId);

  return (
    <Show isLoading={false} title="รายละเอียดแพ็คเกจ" canEdit={false}>
      <Descriptions layout="vertical">
        <Descriptions.Item label="ชื่อแพ็คเกจ">
          {record?.name}
        </Descriptions.Item>
        <Descriptions.Item label="ประเภท">
          {record?.types.map((type: string) => (
            <Tag key={type}>{type}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="รูปแบบ">{record?.period}</Descriptions.Item>
        <Descriptions.Item label="สถานที่ท่องเที่ยว">
          {record?.destination_visit?.name}
        </Descriptions.Item>
        <Descriptions.Item label="คำบรรยายสั่นๆ">
          {record?.desciption}
        </Descriptions.Item>
        <Descriptions.Item label="ราคา">{record?.price}</Descriptions.Item>
        <Descriptions.Item label="รูปภาพ" span={3} style={{ gap: 15 }}>
          <Image.PreviewGroup>
            <Space>
              {record?.images?.map((item: any) => (
                <Image
                  width={150}
                  height={150}
                  src={`${API_URL}${item.asset}`}
                />
              ))}
            </Space>
          </Image.PreviewGroup>
        </Descriptions.Item>
        <Descriptions.Item label="รายละเอียด" span={3}>
          <div dangerouslySetInnerHTML={{ __html: record?.content }} />
        </Descriptions.Item>
        <Descriptions.Item label="เงื่อนไข" span={3}>
          <div dangerouslySetInnerHTML={{ __html: record?.conditions }} />
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};

import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
  ThemedTitleV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";

import { PackageList, ShowPackage } from "./pages/packages";
import { EditProfile, ShowProfile } from "./pages/profile";
import { OnBoarding, RegisterOnboarding } from "pages/onboarding";

import axiosInstance, { API_URL } from "./config/axios";
import DasboardPage from "pages/dashboard";

import { EventsCreate, EventsEdit, EventsList, EventsShow } from "pages/events";

import AppIcon from "components/AppIcon";
import { FlagOutlined, UserOutlined } from "@ant-design/icons";

const GUIDE_ID = localStorage.getItem("guide_id");

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: `/packages?local_guide_id=${GUIDE_ID}`,
                list: `/packages`,
                show: `/packages/show/:id`,
                meta: {
                  label: "งานนำเที่ยว",
                  icon: <FlagOutlined />,
                },
              },
              {
                name: `/local_guides`,
                list: `/local_guides/show/${GUIDE_ID}`,
                edit: `/local_guides/edit/:id`,
                meta: {
                  label: "โปรไฟล์",
                  icon: <UserOutlined />,
                },
              },
              {
                name: "tani_events",
                list: "/events",
                create: "/events/create",
                edit: "/events/edit/:id",
                show: "/events/show/:id",
                meta: {
                  label: "กิจกรรม",
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  GUIDE_ID !== "null" ? (
                    <Navigate to="/" />
                  ) : (
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <Outlet />
                    </Authenticated>
                  )
                }
              >
                <Route
                  path="/onboarding/register"
                  element={<RegisterOnboarding />}
                />
              </Route>
              <Route
                element={
                  GUIDE_ID == "null" ? (
                    <Navigate to="/onboarding/register" />
                  ) : (
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayoutV2
                        Header={() => <Header sticky />}
                        Title={({ collapsed }) =>
                          collapsed ? <span>A</span> : <AppIcon />
                        }
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  )
                }
              >
                <Route path="/" element={<Navigate to="/packages" />} />
                <Route path="/packages">
                  <Route index element={<PackageList />} />
                  <Route path="show/:id" element={<ShowPackage />} />
                </Route>
                <Route path="/local_guides">
                  <Route path="show/:id" element={<ShowProfile />} />
                  <Route path="edit/:id" element={<EditProfile />} />
                </Route>
                <Route path="/events">
                  <Route index element={<EventsList />} />
                  <Route path="create" element={<EventsCreate />} />
                  <Route path="edit/:id" element={<EventsEdit />} />
                  <Route path="show/:id" element={<EventsShow />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;

// update git

import React from "react";

export default function AppIcon() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <img width={50} src="/logo.svg" alt="" />
      <h4
        style={{
          fontWeight: "bold",
          marginTop: 10,
        }}
      >
        ระบบจัดการข้อมูลไกด์ท้องถิ่น
      </h4>
    </div>
  );
}

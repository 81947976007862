import React, { useState } from "react";
import { useLogout, useNavigation, useCreate } from "@refinedev/core";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from "./styles";
import { useSelect } from "@refinedev/antd";
import { Row, Col, Layout, Card, Form, Input, Button, Select } from "antd";
import axiosInstance from "config/axios";
import UploadOne from "components/UploadOne";
import UploadFile from "components/UploadFile";

const { TextArea } = Input;

export interface IRegisterForm {
  name: string;
  address: string;
  detail: string;
  facebook: string;
  phone: string;
  community_id: number;
  profile: object;
  id_card_pdf: object;
  house_registration_pdf: object;
  certificate_pdf: object;
  experience: string;
}

interface ISelect {
  id: number;
  name: string;
}

export const RegisterOnboarding: React.FC = () => {
  const [orgId, setOrgId] = useState(0);

  const [form] = Form.useForm<IRegisterForm>();

  const { push } = useNavigation();
  const selectOrg = useSelect<ISelect>({
    resource: "organizations",
    optionLabel: "name",
    optionValue: "id",
  });

  const { mutate: logout } = useLogout();
  const { mutate: register } = useCreate();

  const selectCommunity = useSelect<ISelect>({
    resource: `${orgId}/communities`,
    queryOptions: {
      enabled: orgId ? true : false,
    },
    optionLabel: "name",
    optionValue: "id",
  });

  const handleRegister = (values: any) => {
    register(
      {
        resource: "local_guides",
        values: {
          local_guide: {
            ...values,
          },
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("error");
        },
        onSuccess: (data: any, variables, context) => {
          localStorage.setItem("guide_id", data?.data?.id);
          window.location.replace("/");
        },
      }
    );
  };

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col>
          <Card
            headStyle={headStyles}
            bodyStyle={bodyStyles}
            style={{
              ...containerStyles,
            }}
          >
            <Form<IRegisterForm>
              layout="vertical"
              form={form}
              onFinish={handleRegister}
            >
              <Row gutter={12}>
                <Col span={24}>
                  <UploadOne label="โปรไฟล์" name="profile" />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="ชื่อ"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="ที่อยู่"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="experience"
                    label="ประสบการณ์"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="facebook"
                    label="เฟสบุ๊ค"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    label="เบอร์โทร"
                    rules={[{ required: false }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="detail"
                    label="รายละเอียด"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="organization_id"
                    label="องค์กรที่อยู่"
                    rules={[{ required: true }]}
                  >
                    <Select
                      {...selectOrg.selectProps}
                      onSearch={undefined}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(id: any) => setOrgId(id)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="community_id"
                    label="ชุมชนที่อยู่"
                    rules={[{ required: false }]}
                  >
                    <Select
                      {...selectCommunity.selectProps}
                      onSearch={undefined}
                      filterOption={(input: any, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!orgId}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="id_card_pdf"
                    label="สำเนาบัตรประชาชน"
                    rules={[{ required: false }]}
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="house_registration_pdf"
                    label="สำเนาทะเบียนบ้าน "
                    rules={[{ required: false }]}
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="certificate_pdf"
                    label="ประกาศนียบัตร(ถ้ามี)"
                    getValueFromEvent={(e: any) => e.file.response}
                  >
                    <UploadFile />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button type="primary" size="large" htmlType="submit" block>
                    บันทึก
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => logout()}
                    danger
                  >
                    ออกจากระบบ
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
